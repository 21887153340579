import React from "react";
import "./style.scss"; // Make sure to import your CSS file

const AutoSlideImage = ({ imageUrl, direction }) => {
  const animationClass =
    direction === "right-to-left" ? "right-to-left" : "left-to-right";

  return (
    <div className="auto-slide-img-container">
      <div className={`auto-slide-img ${animationClass}`}>
        <img src={imageUrl} alt="" />
      </div>
      <div className={`auto-slide-img ${animationClass}`}>
        <img src={imageUrl} alt="" />
      </div>
    </div>
  );
};

export default AutoSlideImage;
